#register-modal {
    border: 1px solid lightgrey;
}

.not_required_container {
position: relative;
}
.not_required_container .not_required_input {
    width: 100% !important;
}
.not_required_span {
    font-size: 14px;
    position: relative;
    top: 2px;
}

#wrong-password-username {
    color: red;
}
.register-btn-header {
    width: 130px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 45px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

    
}

.register-btn-header:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.register-btn-header:focus {
    outline: none;
}
.register-btn-header {
    background-image: linear-gradient(
        to right,
        #25aae1,
        #4481eb,
        #04befe,
        #3f86ed
      );
      box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}