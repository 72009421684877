.FormStepInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 170px;
  }
  
  .FormStepInfo form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    padding: 10px;
  }
  
  .FormStepInfo form .input-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .FormStepInfo form label {
    margin-right: 10px;
  }
  
  .FormStepInfo form input {
    margin: 0 30px 20px 0;
    padding: 10px;
    border: none;
    border-bottom: 1px solid black;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .FormStepInfo .next-back-form1 {
    display: flex;
    justify-content: center;
    margin: 40px auto;
  }
  
  .FormStepInfo .next-back-form1 button {
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .FormStepInfo form {
      width: 90%;
      margin: 0 auto;
    }
    
    .FormStepInfo form input {
      margin: 0 0 20px 0;
    }
  
    .FormStepInfo form .input-container {
      flex-direction: column;
    }
  }
  