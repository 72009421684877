.Home {
    margin: 100px auto;
	text-align: left;
    display: flex;
    justify-content: center;
    width: 80%;
    font-size: 25px;
    
}
.home-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    /* align-items: center; */
    position: relative;
    left: 70px;
}
.home-title-sign {
    
    position: relative;
    top: 40px;
}
.Home h1 strong {
    color: #705df2;
}
.Home p {
    font-size: 20px;
}
.home-sign-up {
    margin: 20px;
    display: flex;
    align-items: center;
}

.home-sign-up button {
    margin-left:40px ;

}
.img-home img {
    width: 500px;
    position: relative;
    /* left: 20px; */
    animation: MoveUpDown 4s linear infinite;
    bottom: 40px;
}


  @keyframes MoveUpDown {
    0%, 100% {
      bottom: 40px;
    }
    50% {
      bottom: 70px;
    }
  }
.emoji {
    width: 70px;
    height: 58px;
    position: relative;
    margin-left: 20px;
    /* background-image: url("./hand-emoji.jpg"); */
}


.register-btn-header {
    width: 130px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 45px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

    
}

.register-btn-header:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.register-btn-header:focus {
    outline: none;
}
.register-btn-header {
    background-image: linear-gradient(
        to right,
        #e17325,
        #eb4a44,
        #fe6c04,
        #ed3f3f
      );
      box-shadow: 0 2px 13px 0 rgba(234, 65, 65, 0.75);
}


@media only screen and (max-width: 860px) {
    .img-home img {
        width: 350px;
    }

    h1 {
        font-size: 35px;
    }
}


@media only screen and (max-width: 660px) {
    .img-home img {
        width: 200px;
    }

    h1 {
        font-size: 35px;
    }

    .home-container {
        left: 0;
    }
    .img-home {
        position: relative;
        top: 100px;
    }
    .emoji {
        width: 0px !important;
    }
}

@media only screen and (max-width: 520px) {
    .img-home img {
        width: 180px;
    }

    h1 {
        font-size: 30px;
    }
    .home-container {
        left: 10px;
    }
    .img-home {
        position: relative;
        top: 100px;
    }
}

@media only screen and (max-width: 485px) {
    .home-container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 500px) {
    .Home {
        margin: 20px auto;
    }
    .img-home img {
        width: 200px;
        margin: 0 auto;
    }
    .img-home {
        top: 0;
        left: 50px;
    }
    .home-title-sign {
        order: 2;
    }
    .home-sign-up{
        justify-content: center;
    }
    h1 {
        font-size: 35px;
        text-align: center;
    }
    .home-container {
        left: 10px;
        gap: 25px;
    }
    .img-home {
        position: relative;
        top: 100px;
    }
}