

.CardTemplate1 a {
  text-decoration: none;
  color: #333;
}
 .CardTemplate1  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 20px; */
    background-color: #fff;
    min-height: 450px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    width: 300px;
    margin: auto;
    border-radius: 25px;
  }
  
.CardTemplate1  .icons {
    display:flex;
    gap: 10px 40px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
  }
  
.CardTemplate1  .photo {
    width: 100%;
    /* max-width: 200px; */
    margin-bottom: 20px;
    height: 200px;
  }
  
.CardTemplate1  .photo img {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
    /* object-fit: contain; */
    /* height: auto; */
    border-radius: 25px 25px 35% 35%;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.328);
  }
  
.CardTemplate1  .details {
    text-align: center;
  }
  
.CardTemplate1  .details h2 {
    font-size: 24px;
    margin: 0 0 10px;
  }
  
.CardTemplate1  .details p {
    font-size: 18px;
    margin: 0 0 20px;
  }

  .CardTemplate1 .location {
    font-size: 30px;
    margin-bottom: 25px;

  }
  
.CardTemplate1  .contact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 30px;
    text-align: center;
    flex-direction: column;
    width: 50px;
  
  }
.CardTemplate1  .contact span {
    font-size: 15px;
    text-align: calc();
  }
  
.CardTemplate1  .contact svg {
    color: #1d1d1d;
    width: 40px;
  }
  
.CardTemplate1  .contact a,
.CardTemplate1  .contact p {
    margin: 0;
    color: #333;
    text-decoration: none;
  }
  
  @media (max-width: 767px) {
  .CardTemplate1  .details h2 {
      font-size: 20px;
    }
  
  .CardTemplate1  .details p {
      font-size: 16px;
    }
  
  .CardTemplate1  .contact {
      font-size: 14px;
    }
  }