
.UserCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0;
}

.user-card-clickable {
    cursor: pointer;
}
.user-card-container {
    width: 336px;
    /* border: 4px solid rgba(48, 48, 48, 0.206); */
    box-shadow: -1px 0px 5px 1px rgba(0, 0, 0, 0.485);
    border-radius: 15px;
    height: 550px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin:   50px ;
    pointer-events: none;

}
.user-card-container > div {
    justify-content: center;
    margin-top: 0px;
    margin: auto;
}
.card-dashboard-buttons {
    display: flex;
    gap: 10px;
    max-width: 280px;
    font-size: 10px !important;
}
.edit-or-share-user-card {
    position: relative;
    bottom: 25px !important;
    gap: 5px;
}
.edit-or-share-user-card p {
    position: relative;
    top: 1px !important;
}
.edit-or-share-user-card {
    position: relative;
    bottom: 25px !important;
}
.edit-or-share-user-card:hover {
    background-color: rgb(2, 77, 206) !important;
    color: white;
}
.delete-user-card {
    position: relative;
    bottom: 25px !important;
    gap: 5px;
}
.delete-user-card p{
    position: relative;
    top: 1px !important;
}
.delete-user-card:hover {
    background-color: rgb(247, 26, 26) !important;
    color: white;
}
.share-user-card {
    position: relative;
    bottom: 25px !important;
    gap: 5px;
}
.share-user-card p{
    position: relative;
    top: 1px !important;
}
.share-user-card:hover {
    background-color: rgb(16, 143, 5) !important;
    color: white;
}


#modal-title {
    text-align: center;
    font-size: 30px;

}

#modal-desc {
    text-align: center;
    font-size: 30px;
}

.share-icons-container {
    display: flex;
    gap: 15px;
    justify-content: center;
}
.share-icons {
    font-size: 30px;
    margin-top: 20px;
}


.modal-container {
    width: 200px;
}

.copy-button-and-text{
    display: flex;
    flex-direction: column;
    width: 150px;
    justify-content: center;
    margin: 0 auto;
}

.copied-span {
    font-size: 12px;
}

.copy-button {
    margin-top: 10px;
  appearance: none;
  background-color: #000000;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  /* margin: 0; */
  min-height: 30px;
  /* min-width: 0; */
  outline: none;
  padding: 4px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* width: 100%; */
  will-change: transform;
}

.copy-button:disabled {
  pointer-events: none;
}

.copy-button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.copy-button:active {
  box-shadow: none;
  transform: translateY(0);
}