.UserDashboard {
	margin-bottom: 160px;
}
.welcome-dashboard {
    text-align: center;
}

.dashboard-all-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.nocards-userDashboard {
    margin-top: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.nocards-userDashboard p {
    font-weight: bold;
    font-size: 50px;
}


.button-to-link-createcard {
  background-color: #fff;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #0d172a;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #0d172a solid;
  text-decoration-thickness: auto;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-to-link-createcard:hover {
  background-color: #1e293b;
  color: #fff;
}

@media (min-width: 768px) {
  .button-to-link-createcard {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}
@media (max-width: 680px) {

  .welcome-dashboard {
    margin-top: 50px;
    margin-bottom: -80px;
  }
}




.UserDashboard .loading {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.UserDashboard .loader {
  text-align: center;
  vertical-align: middle;
  position: relative;
  display: flex;
  background: white;
  padding: 150px;
}

.UserDashboard .loader span {
  display: block;
  width: 20px;
  height: 20px;
  background: #eee;
  border-radius: 50%;
  margin: 0 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.UserDashboard .loader span:nth-child(2) {
  background: #f07e6e;
}

.UserDashboard .loader span:nth-child(3) {
  background: #84cdfa;
}

.UserDashboard .loader span:nth-child(4) {
  background: #5ad1cd;
}

.UserDashboard .loader span:not(:last-child) {
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(30px);
  }
}

.UserDashboard .loader span:last-child {
  animation: jump 1.5s ease-in-out infinite;
}

@keyframes jump {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, -10px);
  }
  20% {
    transform: translate(20px, 10px);
  }
  30% {
    transform: translate(30px, -50px);
  }
  70% {
    transform: translate(-150px, -50px);
  }
  80% {
    transform: translate(-140px, 10px);
  }
  90% {
    transform: translate(-130px, -10px);
  }
  100% {
    transform: translate(-120px, 0);
  }
}