* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.none {
    display: none !important;
}
ul { list-style: none; }
a { text-decoration: unset; }
a:hover { text-decoration: underline; }
ul > li > a { color:#353535; }
ul > li { line-height: 1.5em; }
.footer {
    display: block;
    width: 100%;
    position: relative;
    /* background: #bddaffa6; */
    box-shadow: 2px 0 6px 1px rgba(0, 0, 0, 0.164);
    padding: 150px 25px 50px 25px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 0;
}
.footer-start {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    width: 90%;
    margin: auto;
    padding: 60px 100px;
    /* background-color: rgba(0, 9, 13, 0.907); */
    background-color: rgba(139, 125, 209, 0.907);
    box-shadow: 7px 10px 0px -2px rgba(255, 255, 255, 0.750);
        /* background: linear-gradient(to right top, #705df2, #c0a8f9); */
/* background: linear-gradient(
    50deg,
    hsl(0deg 0% 100%) 0%,
    hsl(255deg 74% 83%) 21%,
    hsl(323deg 29% 77%) 44%,
    hsl(96deg 24% 75%) 67%,
    hsl(161deg 67% 72%) 86%,
    hsl(0deg 0% 100%) 100%
  ); */

    border-radius: 15px;
}
.start-learning {
    position: absolute;
    left: 0;
    right: 0;
    top: -90px;
}
.footer .inner {
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    column-gap: 20px;
    row-gap: 20px;
    max-width: 1180px;
    margin: auto;
    text-align: left;
}
/* .main-logo {
    position: relative;
    display: flex;
    align-items: center;
}
.main-logo .logo {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.main-logo .logo > img {
    display: block;
    width: 100%;
    min-width: 40px;
}
.logo-info {
    text-align: left;
    line-height: 20px;
} */
.text {
    font-size: 17px;
    line-height: 17px;
    color: #fff;
    letter-spacing: .18em;
    font-weight: 600;
}
.copyright {
    color: #fff;
    font-size: 12px;
    line-height: 12px;
}
.footer .column {
    width: 100%;
    font-size: 14px;
    text-align: left;
    padding-left: 100px;
}
.footer .column .column-title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0.5em;
    color: #2b2b2b;
}
.footer .column .column-title:not(:first-child) {
    margin-top: 1em;
}
.illustration {
    left: 8%;
    position: absolute;
    top: -28px;
}
a.button {
    display: inline-flex;
    position: relative;
    height: 60px;
    margin-right: 20px;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    background: #fff;
    color: #705df2;
    border-radius: 36px;
    font-size: 16px;
    font-weight: 700;
    padding: 0 30px;
    text-decoration: unset;
    box-shadow: 0px 24px 74px rgb(60 0 189 / 20%);
}
.section-title {
    font-size: 44px;
    font-weight: 700;
    color: #fff;
}
.section-sub-heading {
    font-size: 22px;
    font-weight: 400;
    color: #fff;
}
.section-sub-heading span {
    opacity: 0.6;
}
.section-sub-heading strong {
    font-weight: 600;
}
@media only screen and (max-width: 990px) and (min-width: 200px) {
    body {
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .footer .inner {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .start-learning {
        position: unset;
    }
    .footer-start {
        width: 100%;
        display: block;
        padding: 30px 20px;
        margin: 30px 0;
    }
    .section-title {
        font-size: 30px;
    }
    .section-sub-heading {
        font-size: 18px;
    }
    footer.footer {
        padding-top: 0;
    }
    a.button {
        height: 50px;
        margin-top: 10px;
    }
    .column.is-logo {
        order: 4;
    }
    .footer .column {
        padding-left: 20px;
    }
}