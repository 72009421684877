.NavBar {
	text-align: right;
    padding: 14px;
    margin: auto;
    display: block;
    /* background-color: var(--color-light-text); */
}
.NavBar a {
    padding: 0 32px;
    font-weight: 600;
    color: var(--color-four);
    padding: 5px 11px;
    margin: 0 3px;
    text-decoration: none;
    border-radius: 15px;
    gap: 5px;
    border-bottom: 1px solid transparent;
}

.NavBar a:hover {
    border-bottom: 1px solid rgba(105, 105, 105, 0.518) ;
    
}

.active {
    border-bottom: 3px solid rgba(48, 48, 48, 0.648) !important;
    /* border-left: 1px solid rgba(48, 48, 48, 0.648) !important; */
    /* border-right: 1px solid rgba(48, 48, 48, 0.648) !important; */
    /* border-top: 1px solid rgba(48, 48, 48, 0.648) !important; */
    /* border:  2px solid  rgba(48, 48, 48, 0.648) !important; */
}



@media only screen and (max-width: 680px) {
    .NavBar {
        display: none;
    }
    
    }