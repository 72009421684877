.Header {
	height: 17%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* color: var(--color-one); */
    /* border-bottom: 1px solid black; */
}

/* .active {
    font-weight:  bold;
    background-color: red !important;
} */

#logout-header {
    color: white;
    background-color: rgb(185, 11, 11);
    cursor: pointer;
}

.login-btn-header {
    width: 130px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 45px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

    
}

.login-btn-header:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.login-btn-header:focus {
    outline: none;
}
.login-btn-header {
    background-image: linear-gradient(
        to right,
        #25aae1,
        #4481eb,
        #04befe,
        #3f86ed
      );
      box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
.logo {
    padding: 10px;
    cursor: pointer;
}
.logo img{
    position: relative;
    top: 2px;
    width: 160px;
    height: 90px;
}


.container {
    width: 60%;
    display: flex;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
@media only screen and (max-width: 1400px) {
    .container {
        width: 80%;
    }
    .login_register_header {
        display: flex;
        flex-direction: column;
    }
    .register-btn-header {
        height: 30px !important;
        font-size: 15px !important;
        margin: 5px !important;
    }
    .login-btn-header {
        height: 30px;
        font-size: 15px;
        margin: 5px;
    }
  }
@media only screen and (max-width: 902px) {
    .container {
        width: 100%;
        
    }

    .Header {
        margin-bottom: 20px;

    }

    .logo img{
        position: relative;
        top: 2px;
        width: 130px;
        height: 70px;
    }


  }

.categories-container{
    width: 100%;
    /* background-color: var(--color-two); */
}

.Header .avatar-header {
    
width: 180px;
display: flex;
justify-content: center;
}

.login_register_header {
    display: flex;
}

@media only screen and (max-width: 680px) {
    .Header {
        margin-top: 15px;
    }
    .logo {
        position: absolute;
        margin: 0 auto;
    }

    .Header .avatar-header {
        width: 80px;
    }

 
 
    
    }
    

@media only screen and (max-width: 480px) {
    .logo img{
        position: relative;
        top: 2px;
        width: 110px;
        height: 60px;
    }
    
    .login-btn-header {
        width: 80px;
        font-size: 15px;
        height: 30px;
    }

    .register-btn-header {
        width: 80px !important;
        font-size: 15px !important;
        height: 30px !important;
    }
 
    }
    